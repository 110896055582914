<template>
  <v-container class="my-5 text-center">
    <h1 class="primary-text">Welcome to EDGEFY</h1>
    <v-flex xs12 class="mb-5">
      <v-img
        src="/img/auth/undraw_dashboard.svg"
        class="ma-5"
        contain
        position="center center"
        aspect-ratio="16/9"
        max-height="600"
      ></v-img>
    </v-flex>
    <div class="text-center mt-3">
      <v-btn outlined color="primary" block x-large :to="`/auth/login`">
        <span>LogIn</span>
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn
        outlined
        color="primary"
        block
        class="mt-3"
        x-large
        :to="`/auth/register`"
      >
        <span>Register</span>
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>
npm r
<script>
export default {
  name: "Register",
  data() {
    return {
      email: "",
      password: "",
      inputRules: [(v) => (v && v.length > 0) || "This field is required"],

      loginURL: "https://edgefy-dev-api.sharky.live/api/v1/auth/login",
    };
  },
  methods: {
    async submitLoginForm() {
      const email = this.$data.email;
      const password = this.$data.password;
      let request = await fetch(this.$data.loginURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      if (request.status === 200 || request.status === 401) {
        const text = await request.text();
        const data = await JSON.parse(text);

        //User ist eingeloggt -> weiterleiten an dashboard
        this.saveResponseDataToStore(data.user, data.token, data.expiresIn);
        this.$router.push("/dashboard");
      } else {
        //some other error
        const text = await request.text();
        const data = await JSON.parse(text);

        //TODO: create error response from the server msg
        console.log(data.msg);
        this.$router.push("/login");
      }
    },
    saveResponseDataToStore(user, token, expirationDate) {
      //saving user
      this.$store.dispatch("user/save_user", user);
      //saving token
      const saveToken = { token: token, expiresIn: expirationDate };
      this.$store.dispatch("user/save_token", saveToken);
      //changing auth status
      this.$store.dispatch("user/change_auth_status", true);
    },
  },
};
</script>

<style>
</style>